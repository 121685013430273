import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import TextCardMiddle from '~/components/molecules/TextCardMiddle'
import PricingForm4 from '~/components/organisms/PrincingForm4'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import Image from '~/components/atoms/Image'

export default function HeroPricing4({ key, index, postcodeParameter, ...props }) {

  const section = props.heroPricing2 ? props.heroPricing2 :  props.heroPricing

  return (
    <section id={section.fieldGroupName + index} className="mb-24 md:mb-40 py-24 pb-20 md:pb-8 bg-sand relative">
      <div className="container">
        <div className={`hidden md:grid grid-cols-2 gap-x-4`}>
          <FadeInWhenVisible className="col-span-2 md:col-span-1 md:col-start-1 -mb-32">
            <TextCard className="text-left " {...section.textCard} image={true} differentextAndTitle={true}/>
            <PricingForm4 className="bg-white border border-[#EFEEEA]" postcodeParameter={postcodeParameter} />
          </FadeInWhenVisible>
          <div className="col-span-2 md:col-span-1 w-full h-full items-center" >
            <Image data={section.textCard.illustration} objectFit="contain" objectPosition="center" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-20 md:hidden">
          <FadeInWhenVisible className="col-span-1">
            <TextCardMiddle className="text-center " {...section.textCard} />
          </FadeInWhenVisible>
          <FadeInWhenVisible className="col-span-1">
            <PricingForm4 className="bg-white border border-[#EFEEEA]" postcodeParameter={postcodeParameter} />
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  )
}
