import React from 'react'
import TextLink from '~/components/atoms/TextLink'
import Button from '~/components/atoms/Button'
import ButtonArrow from '~/components/atoms/ButtonArrow'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TextCardMiddle({
  className = '',
  innerClassName = '',
  buttonContainerClassName = '',
  illustration,
  heading,
  headingClassName = '',
  subheading,
  subheadingColor,
  subheadingClassName = '',
  content,
  buttons,
  alignText = 'left'
}) {

  const headingType = heading?.type
  const HeadingTag = headingType && headingType.substr(0, 1) === "h" ? headingType : "h2";

  if(!illustration && !heading.text && !subheading && !content && !buttons)
    return null

  return (
    
    <div className={`space-y-6 ${className} text-${alignText}`}>
      <div className={`space-y-4 ${innerClassName}`}>
        {subheading && (
					<FadeInWhenVisible transition={{ delay: '.05' }}>
            {subheading && <h6 className={`text-h6 ${(subheadingColor === 'Blue') ? 'text-blue' : 'text-navy text-opacity-50'} ${subheadingClassName}`}>{subheading}</h6>}
          </FadeInWhenVisible>
        )}
        {(heading && heading.text && HeadingTag) && (
					<FadeInWhenVisible transition={{ delay: '.1' }}>
            {(heading && heading.text && HeadingTag) && <HeadingTag className={`text-${headingType} ${headingClassName}`}>{heading.text}</HeadingTag>}
          </FadeInWhenVisible>
        )}
				{illustration && (
					<FadeInWhenVisible>
						<Image data={illustration} className={`h-full w-full ${alignText === 'center' && 'mx-auto'}`} objectFit="contain" objectPosition="center" />
					</FadeInWhenVisible>
				)}
        {content && (
          <FadeInWhenVisible transition={{ delay: '.15' }}>
            {content && <div className="text-body-large prose " dangerouslySetInnerHTML={{ __html: content, }} />}
          </FadeInWhenVisible>
        )}
      </div>

      {buttons?.map((button, i) => (
        <FadeInWhenVisible key={`textCardButton${i}`} transition={{ delay: '.2' }} className={`${buttonContainerClassName}`}>
          {(button.button.type === 'textLink') ? (
            <TextLink {...button.button?.button?.button} />
          ) : (button.button.type === 'arrow') ? (
            <ButtonArrow {...button.button?.button?.button} />
          ) : (
            <Button {...button.button?.button?.button} />
          )}
        </FadeInWhenVisible>

      ))}

    </div>
  )
}
